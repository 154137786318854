<template>
  <div class="demo_content">
    <div class="demo">
      <div class="title">行业解决方案Demo</div>
      <div class="cards">
        <div class="card" v-for="(item,index) in cards" :key="item.id" @click="go">
          <div class="content">
            <img :src="item.img" alt="">
            <p class="name">{{item.name}}</p>
            <p class="info">{{item.info}}</p>
          </div>
          <div class="btn">点击体验</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cards: [
        {
          id: 1,
          img: require('../../assets/img/demo/rj.svg'),
          name: '企业 Demo',
          info: '赋能用户生命周期运营，商品个性化分发，实现高质量业务增长。'
        },
        {
          id: 2,
          img: require('../../assets/img/demo/ds.svg'),
          name: '电商 Demo',
          info: '赋能用户生命周期运营，商品个性化分发，实现高质量业务增长。'
        },
        {
          id: 3,
          img: require('../../assets/img/demo/jt.svg'),
          name: '集团 Demo',
          info: '赋能用户生命周期运营，商品个性化分发，实现高质量业务增长。'
        }
      ]
    }
  },
  created() {},
  mounted() {},
  methods: {
    go() {
      let page = this.$router.resolve({
        name: 'Login'
        // query: {id: id, e:0}
      })
      window.open(page.href, '_blank')
    }
  }
}
</script>

<style lang="less" scoped>
.demo_content {
  height: 100%;
  background: #f8fbfd;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  .demo {
    max-width: 1440px;
    position: relative;
    margin: 0 auto;
    padding-top: 60px;
    padding-bottom: 54px;
    text-align: center;
    .title {
      line-height: 1.5;
      margin-bottom: 56px;
      text-align: center;
      font-size: 32px;
      font-weight: 400;
      color: #2e4446;
      &::after {
        content: '';
        width: 85px;
        height: 3px;
        border-radius: 4px;
        background-color: #317bff;
        display: block;
        margin: 25px auto 0;
      }
    }
    .cards {
      margin: 30px 0 0;
      padding: 0 70px;
      font-size: 0;
      text-align: left;
      position: relative;
      width: 100%;
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;
      flex: 1;
      flex-direction: row;
      justify-content: flex-start;
      .card {
        display: inline-block;
        position: relative;
        width: 289px;
        height: 270px;
        padding: 0 16px;
        margin: 22px 16px;
        border: 0;
        border-radius: 2px;
        text-align: center;
        vertical-align: top;
        cursor: pointer;
        background: #fff;
        box-shadow: 0 2px 10px 0 #ccc;
        .content {
          img {
            height: 46px;
            margin-top: 23px;
          }
          .name {
            margin-top: 19px;
            color: #2e4446;
            font-size: 20px;
            border-top: 1px solid #e7e7e7;
            padding-top: 14px;
          }
          .info {
            margin: 10px 0;
            text-align: justify;
            color: #828f90;
            line-height: 1.785;
            font-weight: 300;
            font-size: 15px;
          }
        }
        .btn {
          display: inline-block;
          min-width: 57px;
          font-size: 14px;
          padding: 7px 15px;
          border: 1px solid transparent;
          color: #fff;
          background: linear-gradient(150deg, #317bff, #1740df);
          border-radius: 2px;
          text-align: center;
          &:hover {
            background: linear-gradient(150deg, #1740df, #317bff);
            transition: all 0.5s ease-in-out;
          }
        }
        &:hover {
          box-shadow: 0 25px 60px -20px #ccc;
          transform: translateY(-8px);
          transition: all 0.4s ease-in-out;
        }
      }
    }
  }
}
</style>